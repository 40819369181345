import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import gsap from "gsap"
import Memoji from "./memoji"
import useStore from "../store"
import Bowser from "bowser"
import { ConsoleView, isMobile } from "react-device-detect"
import { ArrowLeft, ArrowRight } from "./svgs"
import { useWindowSize } from "../utils"

import "swiper/css";
import * as styles from "../scss/carousel.module.scss"

const Carousel = () => {

  const [spaceBetween, setSpaceBetween] = useState(100);
  const [slides, setSlides] = useState([])
  const [currentStep, randomizedMemojis, setCurrentMemoji, selectedMemoji, setPlay] = useStore(state => [state.currentStep, state.randomizedMemojis, state.setCurrentMemoji, state.selectedMemoji, state.setPlay])

  const swiperRef = useRef()
  const carouselRef = useRef()
  const arrowLeftRef = useRef()
  const arrowRightRef = useRef()
  const swiperInited = useRef(false)
  const windowSize = useWindowSize()

  const generateSlides = useCallback(() => {

    const bp = Bowser.parse(window.navigator.userAgent);
    const isIOS = (bp.os.name === 'iOS')

    return randomizedMemojis.map(({ attitude, voeux, slug, sprites, speed, type }, i) => {
      const colors = ['#00ADBA', '#F18700', '#18AE89', '#E4C900', '#F086A7', '#EC625D'];
      return (
        <SwiperSlide className={styles.swiperSlide} key={`s${slug}${i}`}>
          {({ isVisible, isActive }) => (
            <Memoji onMouseEnter={isIOS ? null : playAttitude} isVisible={isVisible} isActive={isActive} voeux={voeux} attitude={attitude} slug={slug} sprites={sprites} speed={speed} type={type} color={gsap.utils.wrapYoyo(colors, i)} />
          )}
        </SwiperSlide>
      )
    })
  }, [randomizedMemojis.length]);

  const buildCarousel = useCallback(() => {
    const w = window.innerWidth >= 500 ? 480 : 320;
    let numSprites = Math.round(window.innerWidth / w) - 1;
    if (!isFinite((window.innerWidth / (w * numSprites)))) {
      numSprites = 1;
    }
    if (numSprites % 2 === 1 && numSprites > 2) {
      numSprites -= 1;
    }
    const spaceBetween = (numSprites === 1) ? (window.innerWidth - w) / 2 : ((window.innerWidth - (w * numSprites)) / numSprites);
    setSlides(generateSlides())
    setSpaceBetween(spaceBetween);
  }, [generateSlides, setSpaceBetween, setSlides])

  const playAttitude = useCallback((e) => {
    if (!swiperRef.current) return;
    const av = swiperRef.current.el.querySelector('.swiper-slide-active .attitudevideo')
    const wv = swiperRef.current.el.querySelector('.swiper-slide-active .wishvideo')
    // If video is playing, no action
    if (!av.paused || !wv.paused) {
      return;
    }
    av.currentTime = 0;
    gsap.to(wv, {
      autoAlpha: 0, onComplete: () => {
        wv.currentTime = 0;
      }
    })
    gsap.to(av, {
      autoAlpha: 1, onComplete: () => {
        // console.log("REQUEST PLAY VIDEO ?", av.getAttribute('data-slug'))
        // On mouse over, don't play video on other memojies
        if (e && e.target && e.target.getAttribute('data-slug') !== av.getAttribute('data-slug')) {
          return;
        }
        // Play attitude
        // if (av && av.currentTime === 0 || (av.currentTime > 0 && av.ended)) {
          // console.log("PLAY VIDEO !", av.getAttribute('data-slug'))
          av.play().catch(error => console.log(error));
        // }
      }
    })
  }, []);

  useLayoutEffect(() => {
    if (swiperInited.current || !windowSize.width) {
      return;
    }
    buildCarousel();
  }, [windowSize.width])

  useEffect(() => {
    if (slides.length === 0 || currentStep !== 3) {
      return;
    }
    const tl = gsap.timeline();
    tl.to(carouselRef.current, { autoAlpha: 1, duration: 3 })
    tl.add(() => {
      swiperRef.current.slideTo(7, 1500, false)
    }, 0.3)
    tl.add(() => {
      swiperInited.current = true;
      setCurrentMemoji(randomizedMemojis[7])
      playAttitude();
      gsap.to(arrowLeftRef.current, { opacity: 1, duration: 0.3 })
      gsap.to(arrowRightRef.current, { opacity: 1, duration: 0.3 })
    }, '-=1.1')
  }, [slides, currentStep, setCurrentMemoji, playAttitude, randomizedMemojis])

  useEffect(() => {
    if (!selectedMemoji) {
      return
    }
    const idx = randomizedMemojis.findIndex(m => m.slug === selectedMemoji.slug)
    swiperRef.current.slideToLoop(idx, 800)
  }, [selectedMemoji, randomizedMemojis])

  return (
    <div id="carousel" ref={carouselRef} className={styles.carousel}>
      <div className={styles.arrowLeft} ref={arrowLeftRef} onClick={() => {
        swiperRef.current.slidePrev();
      }}><ArrowLeft /></div>
      <Swiper
        loop={true}
        slidesPerView={'auto'}
        centeredSlides={true}
        spaceBetween={spaceBetween}
        loopFillGroupWithBlank={true}
        allowTouchMove={false}
        loopedSlides={randomizedMemojis.length}
        watchSlidesProgress={true}
        onSwiper={(swiper) => swiperRef.current = swiper}
        
        onActiveIndexChange={() => {
          // Pause playing video
          const wv = document.querySelector('#carousel .swiper-slide-active .wishvideo');
          if (wv && !wv.paused) {
            wv.pause()
            setPlay(false)
          }
        }}
        onSlideChangeTransitionStart={() => {
          gsap.to(document.querySelector('.game-interface .content', { opacity: 0, duration: 0.3 }), { opacity: 0, duration: 0.3 })
          gsap.to(arrowLeftRef.current, { opacity: 0, duration: 0.3 })
          gsap.to(arrowRightRef.current, { opacity: 0, duration: 0.3 })
          gsap.to('.game-interface-buttons', { opacity: 0, duration: 0.3})
        }
        }
        onSlideChangeTransitionEnd={
          (swiper) => {
            if (swiperInited.current === false) {
              return;
            }
            setCurrentMemoji(randomizedMemojis[swiper.realIndex])
            playAttitude()
            gsap.to(arrowLeftRef.current, { opacity: 1, duration: 0.3 })
            gsap.to(arrowRightRef.current, { opacity: 1, duration: 0.3 })
          }
        }
      >
        {slides}
      </Swiper>
      <div className={styles.arrowRight} ref={arrowRightRef} onClick={() => {
        swiperRef.current.slideNext();
      }}><ArrowRight /></div>
    </div>
  )
}

export default Carousel