import React from "react"
import { Transition } from "react-transition-group"
import * as animations from "../animations"
import Modal from "./modal"
import useStore from "../store"
import Button from "./button"
import { Play } from "./svgs"

import * as styles from "../scss/bravo-popin.module.scss"

const Bravo = () => {

  const [bravoPopinVisible, setBravoVisible] = useStore(state => [state.bravoPopinVisible, state.setBravoVisible])

  return (
    <Transition
      appear
      in={bravoPopinVisible}
      timeout={300}
      mountOnEnter
      unmountOnExit
      onEnter={animations.slideInFloating}
      onExit={animations.slideOut}
    >
      {() => (
        <Modal className={styles.modal} floating dash={false}>
          <div className={styles.bonus}>Bonus</div>
          <div className={styles.imageContainer}>
            <Button className={styles.button} round shadow fillOnOver onClick={() => { setBravoVisible(true) }}>
              <Play />
            </Button>
          </div>
          <div className={styles.caption}>Bravo !<br />Vous avez débloqué la vidéo bonus</div>
        </Modal>
      )}
    </Transition>
  );
};

export default Bravo;