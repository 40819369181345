// extracted by mini-css-extract-plugin
export var subTitle = "message-module--sub-title--X+tHg";
export var textarea = "message-module--textarea--aebS3";
export var slider = "message-module--slider--JEFss";
export var arrowLeft = "message-module--arrow-left--PTNsm";
export var arrowRight = "message-module--arrow-right--UV5Kf";
export var swiper = "message-module--swiper--iUvul";
export var swiperSlide = "message-module--swiper-slide--PbqtZ";
export var button = "message-module--button--UJkEh";
export var message = "message-module--message--a4SJb";
export var error = "message-module--error--FQMuW";