import React from "react"
import Social from "./social"
import useStore from "../store"
import * as styles from "../scss/footer.module.scss"

const Footer = () => {
  const [setLegalVisible, setCookieConsentVisible] = useStore(state => [state.setLegalVisible, state.setCookieConsentVisible])
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.left}>
          <a
            className="underline"
            href="https://justhappiness.fr/?utm_source=voeux2022"
            target="_blank"
            rel="noreferrer"
          >
            justhappiness.fr
          </a>
          <Social />
        </div>
        <div className={styles.right}>
          <a
            href="#"
            className="underline"
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              setCookieConsentVisible(true);
            }}
          >
            Cookies
          </a>
          <a href="#" className="underline" target="_blank" rel="noreferrer"
            onClick={(e) => {
              e.preventDefault();
              setLegalVisible(true);
            }}>
            Mentions légales
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;