import React, { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { Facebook, Twitter, Instagram, LinkedIn } from '../components/svgs'
import Button from '../components/button'
import * as styles from '../scss/social.module.scss'
import { isMobile } from 'react-device-detect'
import useStore from '../store'

const Social = () => {

  const socialRef = useRef();
  const [show, setShow] = useState(false)
  const setMobileSocialVisible = useStore(state => state.setMobileSocialVisible)

  useEffect(() => {
    if (show === null) {
      return
    }
    const elts = socialRef.current.querySelectorAll('button');
    const tl = gsap.timeline();
    if (show) {
      tl.set(elts, { x: -20 });
      tl.to(elts, { x: 0, pointerEvents: 'all', autoAlpha: 1, stagger: { each: 0.02, ease: "power1.out" } })
    } else {
      tl.to(elts, { pointerEvents: 'none', autoAlpha: 0, x: -40, stagger: { each: 0.02, from: "end", ease: "power1.in" }, duration: 0.4 })
    }
    return () => tl.kill()
  }, [show])

  return (
    <>
      <div className={styles.social} onMouseLeave={() => setShow(false)} onMouseEnter={() => isMobile ? null : setShow(true)} onClick={() => isMobile ? setMobileSocialVisible(true) : setShow(show => !show)}>
        <button className="underline">Nous suivre</button>
        <div className={styles.buttons} ref={socialRef}>
          <Button round transparent onClick={() => window.open("https://www.facebook.com/agence.justhappiness/")} ><Facebook /></Button>
          <Button round transparent onClick={() => window.open("https://twitter.com/JustHappinessJH")}><Twitter /></Button>
          <Button round transparent onClick={() => window.open("https://www.instagram.com/we_are_justhappiness/")}><Instagram /></Button>
          <Button round transparent onClick={() => window.open("https://www.linkedin.com/company/just-happiness/")}><LinkedIn /></Button>
        </div>
      </div>
    </>
  )
}

export default Social