import React, { useEffect, useState } from "react"
import { Transition } from "react-transition-group"
import * as animations from "../animations"
import Modal from "./modal"
import useStore from "../store"

import * as styles from "../scss/legal.module.scss"


const Team = () => {

  const [legalVisible, setLegalVisible] = useStore(state => [state.legalVisible, state.setLegalVisible])

  return (
    <Transition
      appear
      in={legalVisible}
      timeout={300}
      mountOnEnter
      unmountOnExit
      onEnter={animations.slideIn}
      onExit={animations.slideOut}
    >
      {() => (
        <Modal className={styles.modal} onClose={() => setLegalVisible(false)} title="Mentions légales" bg={legalVisible}>
          <div className={styles.legal}>
            <div className={styles.wrapper}>
              <div className={styles.sectionTitle}>Informations générales</div>
              L’utilisation de ce site Internet vaut acceptation des conditions mentionnées ci-après.<br />
              JUST HAPPINESS se réserve la possibilité d’engager des poursuites judiciaires en cas de violation de ces conditions d’utilisation, notamment en cas de non-respect et d’utilisation non autorisée des noms et emblèmes JUST HAPPINESS ou MEMOJIS' ROULETTE en cas de violation des droits liés à l’information, au graphisme, aux textes, vidéos, documents audio, photos et images et plus généralement à l’ensemble du contenu du site.<br />

              <div className={styles.sectionTitle}>Éditeur</div>
              JUST HAPPINESS<br />
              460, rue Louis Pasteur – Parc Euromédecine 2<br />
              34790 Grabels – France<br />

              <div className={styles.sectionTitle}>Direction de publication</div>
              Julien Roset (Directeur associé)

              <div className={styles.sectionTitle}>Droits d’auteur</div>
              En application du Code français de la propriété intellectuelle et plus généralement des traités et accords internationaux comportant des dispositions relatives à la protection des droits d’auteurs, il est interdit pour un usage autre que privé ou pédagogique de reproduire, vendre, diffuser, modifier, publier intégralement ou partiellement, sous quelque forme que ce soit les données, la présentation ou l’organisation du site ainsi que les œuvres protégées par les droits d’auteur qui y figurent, sans autorisation écrite particulière et préalable, dont la demande doit être adressée au webmaster.<br /><br />

              Le titre "Don't Stop Me Now" extrait de l'album "Jazz" de Queen, produit par Queen et Roy Thomas Baker, est la propriété exclusive de EMI et Elektra Records.

              <div className={styles.sectionTitle}>Liens</div>
              Les responsables de services en ligne qui créent des liens vers le présent site sont tenus d’en informer le webmaster. Ce site Internet peut comporter des liens vers d’autres sites qui ne sont pas sous contrôle permanent. Ces liens sont considérés comme utiles et pouvant enrichir l’information présente sur le site. JUST HAPPINESS n’endosse aucune responsabilité relative à ces sites, notamment en ce qui concerne l’exactitude ou la fiabilité des informations, des données et des opinions qui y figurent.

              <div className={styles.sectionTitle}>Protection des données personnelles</div>
              Vous ne serez pas amené(e) à communiquer des informations personnelles pendant la visite de ce site Internet.

              <div className={styles.sectionTitle}>Décharge de responsabilité</div>
              JUST HAPPINESS effectue les démarches nécessaires pour assurer la fiabilité des informations contenues dans ce site. Cependant, elle ne peut encourir une quelconque responsabilité du fait d’erreurs, d’omissions ou des résultats qui pourraient être obtenus à la suite de l’usage des informations diffusées. JUST HAPPINESS n’est tenue qu’à une obligation de moyens concernant les informations mises à disposition sur le site. De même, elle n’est en aucun cas responsable de l’infection par des virus ou de tout autre problème informatique ayant des effets destructeurs.

              <div className={styles.sectionTitle}>Les cookies</div>
              En faisant usage de ce site, lors de votre première visite ou vos visites ultérieures, vous pouvez configurer la manière dont les cookies seront utilisés. Vous ne pourrez pas refuser des cookies « fonctionnels », mais vous pourrez refuser des cookies de « tracking » (Google Analytics) car cela n’empêche pas le site de fonctionner.
              Un « cookie » est un petit fichier texte qui est envoyé à votre navigateur et stocké sur le disque dur de celui-ci lorsque vous visitez notre site. Chaque fois que vous utilisez le même ordinateur pour accéder à notre site, nos serveurs web sont informés de votre visite et à notre tour, nous pouvons savoir que vous avez visité votre site, et de quelle manière.<br />
              Pour revenir sur votre choix initial concernant les cookies, vous pourrez cliquer sur le lien "Cookies" en bas de votre écran pendant votre visite.

              <div className={styles.sectionTitle}>Cookies fonctionnels</div>
              Certains cookies sont nécessaires pour le fonctionnement de notre site. Par exemple, lorsque vous reprenez votre parcours après avoir quitté le site un cookie nous aidera à vous reconnaître comme ayant déjà consulté des voeux. Ces cookies ne peuvent pas être désactivés, certains aspects du site ne pourraient pas fonctionner sans eux.
              Ces cookies ne contiennent aucune information personelle.

              <div className={styles.sectionTitle}>Cookies de tracking</div>
              Ce site Internet utilise Google Analytics pour analyser la façon dont les utilisateurs utilisent le site. L’outil utilise des cookies, qui sont des fichiers textes placés sur votre ordinateur, pour collecter, de manière anonyme, les informations relatives à la connexion à Internet et au comportement du visiteur. Les informations générées par les cookies à propos de votre utilisation du site web (incluant l’adresse IP) sont transmises à Google. Ces informations sont ensuite utilisées pour évaluer l’utilisation du site par les visiteurs et rassembler des données statistiques concernant l’activité de notre site Internet. Nous n’associerons pas les données recueillies sur ce site à des informations personnelles identifiables d’autres sources.
            </div>
          </div>
        </Modal>
      )}
    </Transition>
  );
};

export default Team;