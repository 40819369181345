import React, { useEffect, useRef } from 'react'
import Carousel from "../components/carousel"
import GameInterface from "../components/game-interface"
import useStore from "../store"
import * as styles from '../scss/game.module.scss'
import gsap from "gsap"

const Game = () => {

  const currentStep = useStore(state => state.currentStep)

  const ref = useRef()

  useEffect(() => {
    if (currentStep === 3) {
      gsap.to(ref.current, { autoAlpha: 1, pointerEvents: 'all', duration: 0.3 })
    }
  }, [currentStep])

  return (
    <div className={styles.game} ref={ref}>
      <Carousel />
      <GameInterface />
    </div>
  )
}

export default Game