import React, { useCallback, useEffect, useState } from "react"
import Roulette from "../screens/roulette"
import Welcome from "../screens/welcome"
import Circle from "../components/circle"
import Game from "../screens/game"
import Background from "../components/background"
import { AudioNotice } from "../components/small";
import Loader from "../components/loader";
import Header from "../components/header"
import Footer from "../components/footer"
import Message from "../components/message"
import SocialMobile from "../components/social-mobile"
// import Bowser from "bowser"
// import Canvas from "../components/canvas"
import Overlay from "../components/overlay"
import Team from "../components/team"
import Bravo from "../components/bravo"
import BravoPopin from "../components/bravo-popin"
// import ConfigurationPopin from "../components/configuration-popin"
import Legal from "../components/legal"
import CookieConsent from "../components/cookie-consent"
import Noise from "../components/noise"
import TooSmall from "../components/too-smal"
import gsap from "gsap"
import { Transition } from "react-transition-group"
import * as animations from "../animations"
import "../scss/global.scss"
import { Helmet } from "react-helmet"
import ReactGA from 'react-ga4';
import { isMobile } from "react-device-detect"

import useStore from "../store"

const IndexPage = () => {

  const [currentStep, setCurrentStep, cookieConsent, configuration] = useStore(state => [state.currentStep, state.setCurrentStep, state.cookieConsent, state.configuration])
  const [circles, setCircles] = useState()

  const renderCircles = useCallback((nb) => {
    if (typeof window === "undefined") {
      return;
    }
    const circles = [];
    if (configuration.bokeh === true) {
      circles.push(<Circle
        key="r1"
        size="200px"
        top="200px"
        left="100px"
        color="#ffffff"
        opacity={0.1}
        blur={0.1}
      />)
      circles.push(<Circle
        key="r2"
        size="50px"
        top="220px"
        left="270px"
        color="#ffffff"
        opacity={0.5}
        blur={8}
      />)
      circles.push(<Circle
        key="r3"
        size="120px"
        bottom="100px"
        left="200px"
        color="#ffffff"
        opacity={0.1}
        blur={1}
      />)
      // circles.push(<Circle
      //   key="r4"
      //   size="150px"
      //   bottom="150px"
      //   right="200px"
      //   color="#ffffff"
      //   opacity={0.1}
      //   blur={0}
      // />)
      circles.push(<Circle
        key="r5"
        size="140px"
        bottom="50px"
        right="100px"
        color="#ffffff"
        opacity={0.1}
        blur={1}
      />)
      circles.push(<Circle
        key="r6"
        size="30px"
        top="140px"
        right="200px"
        color="#ffffff"
        opacity={0.7}
        blur={5}
      />)
    }
    for (let i = 0; i < nb; ++i) {
      let left = gsap.utils.random(30, window.innerWidth, 45);
      let top = gsap.utils.random(30, window.innerHeight, 45);
      circles.push(
        <Circle
          key={`c${i}`}
          size="2px"
          top={`${top}px`}
          left={`${left}px`}
          color="white"
          opacity={0.7}
          blur={0}
        />
      );
    }
    return circles;
  }, [configuration]);

  useEffect(() => {
    // const bp = Bowser.parse(window.navigator.userAgent);
    // const isIOS = (bp.os.name === 'iOS')
    // if (isIOS) return;
    setCircles(renderCircles(configuration.stars))
  }, [configuration, renderCircles])

  useEffect(() => {
    if (cookieConsent === true) {
      ReactGA.initialize('G-TZ1GDX9QV8');
      ReactGA.send("pageview");
    }
  }, [cookieConsent]);

  return (
    <>
      <Helmet>
        <title>Memojis' Roulette</title>
        <meta property="og:image" content="/images/apercu-lien.jpg" />
        <meta property="og:title" content="Memojis' Roulette" />
      </Helmet>
      <Background css={["background", "withImage"]} />
      <Background css={["background", "withGradient", "blackAndWhite"]} />
      {circles}
      <Transition appear mountOnEnter unmountOnExit in={currentStep === 3} timeout={300} onEnter={animations.slideDown}>
        <Header />
      </Transition>
      <Transition appear mountOnEnter unmountOnExit in={currentStep === 0} timeout={0} onEnter={animations.opacityIn} onExit={animations.opacityOut}>
        <Loader onComplete={() => setCurrentStep(1)} />
      </Transition>
      <Transition appear mountOnEnter unmountOnExit in={currentStep === 1} timeout={300} onExit={animations.opacityOut}>
        <Roulette />
      </Transition>
      <Transition appear mountOnEnter unmountOnExit in={currentStep === 2} timeout={300} onExit={animations.opacityOut}>
        <Welcome onClick={() => setCurrentStep(3)} />
      </Transition>
      <Transition appear mountOnEnter unmountOnExit in={currentStep >= 1} timeout={300} onEnter={animations.slideUp}>
        <Footer />
      </Transition>
      {currentStep === 3 && (
        <>
          {!isMobile && <BravoPopin />}
        </>
      )}
      <Game />
      <AudioNotice />
      <Message />
      <Team />
      <Bravo />
      <SocialMobile />
      <CookieConsent />
      {/* {!isMobile && <ConfigurationPopin />} */}
      <Legal />
      {configuration.noise && <Noise />}
      <Overlay />
      <TooSmall />
    </>
  );
};

export default IndexPage;