const utils = {
  norm: function (value, min, max) {
    return (value - min) / (max - min);
  },

  lerp: function (norm, min, max) {
    return (max - min) * norm + min;
  },

  map: function (value, sourceMin, sourceMax, destMin, destMax) {
    return utils.lerp(utils.norm(value, sourceMin, sourceMax), destMin, destMax);
  },

  clamp: function (value, min, max) {
    return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
  },

  distance: function (p0, p1) {
    const dx = p1.x - p0.x,
      dy = p1.y - p0.y;
    return Math.sqrt(dx * dx + dy * dy);
  },

  distanceXY: function (x0, y0, x1, y1) {
    const dx = x1 - x0,
      dy = y1 - y0;
    return Math.sqrt(dx * dx + dy * dy);
  },

  circleCollision: function (c0, c1) {
    return utils.distance(c0, c1) <= c0.radius + c1.radius;
  },

  circlePointCollision: function (x, y, circle) {
    return utils.distanceXY(x, y, circle.x, circle.y) < circle.radius;
  },

  pointInRect: function (x, y, rect) {
    return utils.inRange(x, rect.x, rect.x + rect.width) &&
      utils.inRange(y, rect.y, rect.y + rect.height);
  },

  inRange: function (value, min, max) {
    return value >= Math.min(min, max) && value <= Math.max(min, max);
  },

  rangeIntersect: function (min0, max0, min1, max1) {
    return Math.max(min0, max0) >= Math.min(min1, max1) &&
      Math.min(min0, max0) <= Math.max(min1, max1);
  },

  rectIntersect: function (r0, r1) {
    return utils.rangeIntersect(r0.x, r0.x + r0.width, r1.x, r1.x + r1.width) &&
      utils.rangeIntersect(r0.y, r0.y + r0.height, r1.y, r1.y + r1.height);
  },

  degreesToRads: function (degrees) {
    return degrees / 180 * Math.PI;
  },

  radsToDegrees: function (radians) {
    return radians * 180 / Math.PI;
  },

  randomRange: function (min, max) {
    return min + Math.random() * (max - min);
  },

  randomInt: function (min, max) {
    return Math.floor(min + Math.random() * (max - min + 1));
  }

}

function randomInt(min, max) {
  return min + Math.random() * (max - min);
}

function clamp(value, min, max) {
  return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
}

function particle(context, x, y, gravity, friction, wind, colors) {
  this.radius = randomInt(.1, 1);
  this.context = context;
  this.x = x;
  this.y = y;
  this.gravity = gravity;
  this.friction = friction;
  this.wind = wind;
  this.vx = randomInt(-4, 4);
  this.vy = randomInt(-10, -0);
  // this.type = utils.randomInt(0, 1);
  this.type = 0;
  this.w = utils.randomRange(5, 20);
  this.h = utils.randomRange(5, 20);
  this.r = utils.randomRange(5, 10);
  this.angle = utils.degreesToRads(randomInt(0, 360));
  this.anglespin = randomInt(-0.2, 0.2);
  this.color = colors[Math.floor(Math.random() * colors.length)];
  this.rotateY = randomInt(0, 1);
}

particle.prototype.update = function () {
  this.x += this.vx;
  this.y += this.vy;
  this.vy += this.gravity;
  this.vx += this.wind;
  this.vx *= this.friction;
  this.vy *= this.friction;
  this.radius -= .02;
  if (this.rotateY < 1) {
    this.rotateY += 0.1;
  } else {
    this.rotateY = -1;
  }
  this.angle += this.anglespin;
  this.context.save();
  this.context.translate(this.x, this.y);
  this.context.rotate(this.angle);
  this.context.scale(1, this.rotateY);
  this.context.rotate(this.angle);
  this.context.beginPath();
  this.context.fillStyle = this.color;
  this.context.strokeStyle = this.color;
  this.context.lineCap = "round";
  this.context.lineWidth = 2;
  if (this.type === 0) {
    this.context.beginPath();
    this.context.arc(0, 0, this.r, 0, 2 * Math.PI);
    this.context.fill();
  } else if (this.type === 2) {
    this.context.beginPath();
    for (let i = 0; i < 22; i++) {
      const angle = 0.5 * i;
      const x = (.2 + 1.5 * angle) * Math.cos(angle);
      const y = (.2 + 1.5 * angle) * Math.sin(angle);
      this.context.lineTo(x, y);
    }
    this.context.stroke();
  } else if (this.type === 1) {
    this.context.fillRect(-this.w / 2, -this.h / 2, this.w, this.h);
  }
  this.context.closePath();
  this.context.restore();
}

// function confettis(x, y, w, h, number, text) {
function confettis(container, number) {
  // particle will spawn in this aera
  // this.w = w;
  // this.h = h;
  this.number = number;
  this.particles = [];
  this.x = 0;
  this.y = 0;
  this.recycle = true;
  this.type = 0;
  this.gravity = 0.2 // min : -1, max: 1, step: 0.1
  this.friction = 0.99 // min: 0.1, max: 0.99, step: 0.01
  this.wind = 0 // min : -1, max: 1, step: 0.1
  this.container = container;
  this.colors = [
    '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5',
    '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4CAF50',
    '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800',
    '#FF5722', '#795548'
  ];
  const canvas = document.createElement('canvas')
  this.context = canvas.getContext('2d');
  this.w = canvas.width = container.offsetWidth;
  this.h = canvas.height = container.offsetHeight;
  container.appendChild(canvas)
}

confettis.prototype.animate = function () {
  if (this.particles.length < this.number) {
    this.particles.push(new particle(this.context, clamp(randomInt(this.x, this.w + this.x), this.x, this.w + this.x),
      clamp(randomInt(this.y, this.h + this.y), this.y, this.h + this.y), this.gravity, this.friction, this.wind, this.colors));
  }

  if (this.particles.length > this.number) {
    this.particles.length = this.number;
  }

  for (let i = 0; i < this.particles.length; i++) {
    const p = this.particles[i];
    p.update();
    // if (p.y > this.h || p.y < -100 || p.x > this.w + 100 || p.x < -100 && this.recycle) {
    //   //a brand new particle replacing the dead one
    //   this.particles[i] = new particle(this.context, clamp(randomInt(this.x, this.w + this.x), this.x, this.w + this.x),
    //     clamp(randomInt(this.y, this.h + this.y), this.y, this.h + this.y), this.gravity, this.friction, this.wind, this.colors);
    // }
  }
}

confettis.prototype.update = function () {
  // this.context.fillStyle = "transparent"; 
  if (this.container) {
    this.context.clearRect(0, 0, this.w, this.h);
    this.animate();
    requestAnimationFrame(this.update.bind(this));
  }
}

confettis.prototype.kill = function() {
  this.particles = [];
  this.container.querySelector('canvas').remove();
}

export default confettis