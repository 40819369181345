import React, { useEffect, useLayoutEffect, useRef } from "react"
import { Transition } from "react-transition-group"
import YouTube from 'react-youtube';
import gsap from "gsap"
import { Play } from "./svgs";
import * as animations from "../animations"
import Button from "./button"
import Modal from "./modal"
import useStore from "../store"
import confettis from "../confettis"

import * as styles from "../scss/bravo.module.scss"

const Bravo = () => {

  const [bravoVisible, setBravoVisible, viewed, bravoPopinVisible, setBravoPopinVisible, cookieConsent] = useStore(state => [state.bravoVisible, state.setBravoVisible, state.viewed, state.bravoPopinVisible, state.setBravoPopinVisible, state.cookieConsent])
  const confettisRef = useRef()
  const coverRef = useRef()
  const buttonRef = useRef()
  const youtubeContainerRef = useRef()
  const youtubePlayer = useRef()

  const onClose = () => {
    if (viewed.length === 3 && !bravoPopinVisible) {
      setTimeout(() => {
        setBravoPopinVisible(true)
      }, 300)
    }
    setBravoVisible(false)
  }

  useLayoutEffect(() => {
    if (!confettisRef.current || !bravoVisible) {
      return;
    }
    const anim = new confettis(confettisRef.current, 80);
    setTimeout(() => {
      anim.update();
    }, 400)
    return () => {
      anim.kill();
    }
  }, [bravoVisible])


  // useEffect(() => {
  //   if (cookieConsent === true && bravoVisible) {
  //     ReactGA.event({ category: 'level_up', action: 'unlock_achievement', achievement_id: 'VIDEO_BONUS' });
  //   }
  // }, [cookieConsent, bravoVisible]);

  const playYoutubeVideo = () => {
    gsap.to([coverRef.current, buttonRef.current], { autoAlpha: 0 })
    gsap.to(youtubeContainerRef.current, {
      autoAlpha: 1
    })
    youtubePlayer.current.playVideo()
    // gsap.to(youtubeContainerRef.current, {
    //   autoAlpha: 1, onComplete: () => {
    //     youtubePlayer.current.playVideo()
    //   }
    // })
  }

  const closeModal = () => {
    if (youtubePlayer.current) {
      youtubePlayer.current.stopVideo();
    }
    onClose();
  }

  return (
    <Transition
      appear
      in={bravoVisible}
      timeout={300}
      mountOnEnter
      unmountOnExit
      onEnter={animations.slideInFloating}
      onExit={animations.slideOut}
    >
      {() => (
        <Modal floating onClose={closeModal} title="Bonus débloqué !" bg={bravoVisible}>

          <div className={styles.bravo}>
            Félicitation, tu as débloqué la vidéo Bonus de la Team JH. Merci pour cette folle année passée, faisons de 2022 l’année du plaisir et de la créativité libérée. Bonne année !
          </div>
          <div className={styles.confettis} ref={confettisRef}></div>
          <div className={styles.videoContainer}>
            <img ref={coverRef} className={styles.cover} src="/images/cover_multi.jpg" />
            {/* <iframe ref={youtubeRef} id="ytplayer" type="text/html" width="100%" height="auto" allow="autoplay"
              title="Bravo"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=0&enablejsapi=1&origin=https://voeux20202.beve.dev&playsinline=true"
              frameBorder="0" />
            </div> */}
            <div className={styles.youtubeContainer} ref={youtubeContainerRef}>
              <YouTube onReady={(e) => youtubePlayer.current = e.target} opts={{ height: '100%', width: '100%', rel: 0, controls: 0, disablekb: 1, modestbranding: 1, playsinline: 1, showinfo: 0 }} videoId="A5EK_AN7waI" className={styles.youtubeVideo} containerClassName={styles.youtubeVideoContainer} />
            </div>
            <Button ref={buttonRef} className={styles.videoButton} round shadow fillOnOver onClick={playYoutubeVideo}>
              <Play />
            </Button>
          </div>
          <Button
            outline
            className={styles.button}
            onClick={onClose}
          >
            Continuer à voir les voeux
          </Button>
        </Modal>
      )}
    </Transition>
  );
};

export default Bravo;