import Cookies from "js-cookie";
import React, { useEffect } from "react";
import * as styles from "../scss/cookie-consent.module.scss";
import { Transition } from "react-transition-group";
import { Cookie } from "./svgs";
import * as animations from "../animations";
import Modal from "./modal";
import Button from "./button";
import useStore from "../store"

const CookieConsent = () => {
  const [cookieConsentVisible, setCookieConsentVisible, cookieChoice, setCookieChoice, setCookieConsent] = useStore(state => [state.cookieConsentVisible, state.setCookieConsentVisible, state.cookieChoice, state.setCookieChoice, state.setCookieConsent])

  useEffect(() => {
    if (cookieChoice) {
      return;
    }
    setTimeout(() => {
      setCookieConsentVisible(true);
    }, 500);
  }, [cookieChoice, setCookieConsentVisible]);

  return (
    <Transition
      appear
      in={cookieConsentVisible}
      timeout={300}
      onEnter={animations.slideInFloating}
      onExit={animations.slideOut}
    >
      {() => (
        <Modal className={styles.modal} title="Cookies" floating bg={cookieConsentVisible}>
          <Cookie />
          <div className={styles.bold}>
            Nous utilisons des cookies pour le bon fonctionnement du site et
            pour vous permettre de continuer à jouer lors de votre prochaine
            visite !
          </div>
          <div>
            Acceptez-vous l'utilisation de cookies de tracking pour nous
            permettre de consulter les statistiques de notre site des voeux ?
          </div>
          <div className={styles.buttons}>
            <Button
              outline
              onClick={() => {
                Cookies.set("cookie_consent", false)
                setCookieConsent(false)
                setCookieChoice(true)
                setCookieConsentVisible(false)
              }}
            >
              Je refuse
            </Button>
            <Button
              outline
              onClick={() => {
                Cookies.set("cookie_consent", true)
                setCookieConsent(true)
                setCookieChoice(true)
                setCookieConsentVisible(false)
              }}
            >
              J'accepte
            </Button>
          </div>
        </Modal>
      )}
    </Transition>
  );
};

export const resetCookieConsent = () => {
  Cookies.remove('viewed')
  Cookies.remove('configuration')
  Cookies.remove('cookie_consent')
}

export const getCookie = (name) => {
  return Cookies.get('cookie_consent') && Cookies.get(name) ? Cookies.get(name) : null;
}

export const setCookie = (name, value, withConsent = false) => {
  if (!withConsent || Cookies.get('cookie_consent') === 'true') {
    Cookies.set(name, value)
    return true
  }
  return false
}

export default CookieConsent;
