import React, { useEffect, useRef, useState } from "react"
import { isMobile } from "react-device-detect"
import * as styles from "../scss/small.module.scss"
import { Headphones } from "./svgs"
import useStore from "../store"
import gsap from "gsap"

export const AudioNotice = () => {

  const [currentStep, cookieChoice] = useStore(state => [state.currentStep, state.cookieChoice])
  const [className, setClassName] = useState(styles.hidden)
  const ref = useRef()

  useEffect(() => {
    if (currentStep === 1) {
      gsap.to(ref.current, { autoAlpha: 1, bottom: isMobile ? 70 : 0, ease: 'power1.out', duration: 0.4 })
    }
    if (currentStep === 2) {
      gsap.to(ref.current, { autoAlpha: 0, display: 'none' })
    }
  }, [currentStep])

  useEffect(() => {
    setClassName(cookieChoice ? styles.visible : styles.hidden)
  }, [cookieChoice])

  return (
    <div ref={ref} className={`${styles.headphones} ${className}`}>
      <Headphones />
      <div className={styles.headphonesCaption}>Activez votre son pour une meilleure expérience</div>
    </div>
  )
}