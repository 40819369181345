import React from "react"
import { Check } from "./svgs"
import * as styles from "../scss/memoji-icon.module.scss"

const MemojiIcon = ({ big, color, icon, name, onClick, isChecked, opacity, transparent}) => {

  return (
    <div className={big ? styles.bigMemojiIcon : styles.memojiIcon} onClick={onClick} style={{opacity: (opacity && isChecked || transparent) ? 0.5 : 1}}>
      <div className={styles.circle} style={{ backgroundColor: color }}></div>
      {isChecked && !opacity && <Check className={styles.check} />}
      <img src={icon} />
      <div className={big ? styles.bigName : styles.name}>{name}</div>
    </div>
  )
}

export default MemojiIcon