// extracted by mini-css-extract-plugin
export var header = "header-module--header--kC5lg";
export var left = "header-module--left--zaSZD";
export var scorePoints = "header-module--scorePoints--ZFfV+";
export var scoreSep = "header-module--scoreSep--AL7dE";
export var center = "header-module--center--lEqf0";
export var jh = "header-module--jh--+Bjvy";
export var right = "header-module--right--F3lzb";
export var bonusStars = "header-module--bonusStars--EeFF2";
export var star = "header-module--star--e0+Hu";
export var starActive = "header-module--starActive--ppWpU";