import React, { useEffect, useRef, useState } from "react"
import { Transition } from "react-transition-group"
import { Swiper, SwiperSlide } from "swiper/react"
import Bowser from "bowser"
import * as animations from "../animations"
import Modal from "./modal"
import Button from "./button"
import MemojiIcon from "./memoji-icon"
import { Plane, ArrowLeft } from "./svgs"
import memojis from "../memojis"
import useStore from "../store"
import { isMobile } from "react-device-detect"
import gsap from "gsap"

import * as styles from "../scss/message.module.scss"

const Message = () => {
  const [slides, setSlides] = useState([]);
  const [recipients, setRecipients, messageVisible, setMessageVisible, videoCache, currentMemoji] = useStore(state => [state.recipients, state.setRecipients, state.messageVisible, state.setMessageVisible, state.videoCache, state.currentMemoji])
  const [message, setMessage] = useState('')
  const [sent, setSent] = useState(null)
  const [sendMessage, setSendMessage] = useState(null)
  const swiperRef = useRef()
  const textareaRef = useRef()
  // const savedViewport = useRef()
  const sliderContainerRef = useRef()
  const writeToRef = useRef()

  useEffect(() => {
    if (!currentMemoji) {
      return;
    }

    setSlides(() => {

      const colors = ['#00ADBA', '#F18700', '#18AE89', '#E4C900', '#F086A7', '#EC625D'];

      // Set slug at first position

      const m = [];
      memojis.forEach((mem) => {
        if (currentMemoji.slug === mem.slug) {
          m.unshift(mem)
        } else {
          m.push(mem)
        }
      })

      return m.map(({ name, slug }, i) => {
        const icon = videoCache[`image_${slug}`];
        return (
          <SwiperSlide className={styles.swiperSlide} key={slug + i}>
            <MemojiIcon transparent={!recipients.includes(slug)} icon={icon} color={gsap.utils.wrapYoyo(colors, i)} slug={slug} name={name} isChecked={recipients.includes(slug)} onClick={() => setRecipients(slug)} />
          </SwiperSlide>
        )
      })
    })

  }, [recipients, setRecipients, videoCache, currentMemoji]);

  // useLayoutEffect(() => {
  // }, [])

  useEffect(() => {
    // savedViewport.current = document.querySelector('meta[name="viewport"]').getAttribute("content")
    // const viewport = document.querySelector('meta[name="viewport"]');
    if (window.innerWidth > 500 || !textareaRef.current) {
      return;
    }
    const bp = Bowser.parse(window.navigator.userAgent);
    const isSafari = (bp.browser.name === 'Safari' || (bp.os.name === 'iOS'))
    textareaRef.current.addEventListener('focus', () => {
      //  = viewport.getAttribute("content")
      // viewport.setAttribute("content", "height=" + window.innerHeight + "px, width=" + window.innerWidth + "px, initial-scale=1.0");
      if (!isSafari) {
        gsap.to([sliderContainerRef.current, writeToRef.current], {
          height: 0, autoAlpha: 0, onComplete: () => {
            gsap.set([sliderContainerRef.current, writeToRef.current], { display: 'none' })
          }
        })
      }
    })
    textareaRef.current.addEventListener('blur', () => {
      if (!isSafari) {
        gsap.to([sliderContainerRef.current, writeToRef.current], {
          display: 'flex', height: 'auto', autoAlpha: 1, onComplete: () => {
            // swiperRef.current.update();
          }
        })
      }
    });
    // return () => {
    //   viewport.setAttribute("content", savedViewport.current)
    //   // console.log("BLYE");
    // }
    
  }, [textareaRef, messageVisible])

  // useEffect(() => {
  //   if (window.innerWidth > 500) {
  //     return;
  //   }
  //   if (!messageVisible) {
  //     const viewport = document.querySelector('meta[name="viewport"]');
  //     viewport.setAttribute("content", savedViewport.current)
  //     gsap.to(modalRef.current, { y: 0 })
  //     console.log("CIAO")
  //   }
  // }, [messageVisible])

  useEffect(() => {
    if (sent === true) {
      setRecipients([])
      setMessage('')
      setSendMessage("Le message a bien été envoyé.")
      setSent(null)
    } else if (sent !== null) {
      setSendMessage("Le message n'a pas pu être envoyé. Merci de réesayer plus tard.")
    }
  }, [sent, setRecipients])

  const sendMail = async () => {

    if (!message) {
      return;
    }

    const body = new URLSearchParams();
    body.append('m', message)
    body.append('s', recipients.join('_'))

    const res = await fetch("/server/sldji28k.php", {
      method: "POST",
      body
    })

    if (res.ok) {
      const json = await res.json();
      if (json === true) {
        setSent(true)
      } else {
        setSent(false)
      }
    } else {
      setSent(false)
      console.error('Erreur lors de l\'envoi');
    }

  }

  const slideTo = (num) => {
    let newPos = swiperRef.current.realIndex + num;
    if (newPos < 0) {
      newPos = 0;
    }
    if (newPos >= memojis.length) {
      newPos = memojis.length;
    }
    swiperRef.current.slideTo(newPos)
  }

  return (
    <Transition
      appear
      in={messageVisible}
      timeout={300}
      mountOnEnter
      unmountOnExit
      onEnter={animations.slideIn}
      onExit={animations.slideOut}
    >
      {() => (
        <Modal onClose={() => { setSendMessage(null); setMessageVisible(false) }} title="Répondre" bg={messageVisible}>
          <div ref={writeToRef} className={styles.subTitle}>Écrire à</div>
          <div className={styles.slider} ref={sliderContainerRef}>
            <button className={styles.arrowLeft} onClick={() => slideTo(-3)}><ArrowLeft /></button>
            <Swiper
              loop={false}
              slidesPerView={'auto'}
              spaceBetween={12}
              centeredSlides={false}
              allowTouchMove={isMobile}
              onSwiper={(swiper) => swiperRef.current = swiper}
              className={styles.swiper}
            >
              {slides}
            </Swiper>
            <button className={styles.arrowRight} onClick={() => slideTo(3)}><ArrowLeft /></button>
          </div>
          <div className={styles.subTitle}>Votre message,<br />sans oublier de signer</div>
          <textarea ref={textareaRef} className={styles.textarea} onChange={(e) => setMessage(e.target.value)}></textarea>
          {sendMessage !== '' && (<div className={styles.message}>{sendMessage}</div>)}
          <Button
            round
            shadow
            className={styles.button}
            disabled={recipients.length <= 0 || !message}
            onClick={sendMail}
          >
            <Plane />
          </Button>
        </Modal>
      )}
    </Transition>
  );
};

export default Message;
