import React, {useCallback, useLayoutEffect, useRef} from "react"
import * as styles from "../scss/circle.module.scss"
import gsap from "gsap"

const Circle = ({size, top, bottom, left, right, color, opacity, blur}) => {

  const ref = useRef()

  const randomAnim = useCallback(() => {
    const s = parseInt(size);
    let r = s*0.5;
    if (r < 5) {
      r = 15;
    }
    const x = gsap.utils.random(-r, r, 1) 
    const y = gsap.utils.random(-r, r, 1) 
    const tl = gsap.timeline();
    return tl.to(ref.current, {x, y, duration: 8, ease: 'linear', yoyo: true, onComplete: () => {
      randomAnim();
    }})
  }, [size])

  useLayoutEffect(() => {
    const anim = randomAnim()
    return () => {
      anim.kill();
    }
  }, [randomAnim])

  return (
    <div ref={ref} className={styles.circle} style={{width: size, height: size, top, bottom, left, right, backgroundColor: color, filter: `blur(${blur})`, opacity}}></div>
  )
}

export default Circle;