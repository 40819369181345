const memojis = [
  {
    slug: "julien",
    name: "Julien",
    type: "video",
    voeux: "voeux_julien",
    attitude: "attitude_julien",
    icon: 'icones/julien.png',
    title: "Julien, c’est l'un<br/>des deux Directeurs Associés",
    // description: "Il pilote JH et apporte sa vision stratégique sur les dossiers.\nJulien vit d’insights et de Coca-Cola. D’ailleurs, on ne l’a jamais vu s’approcher d’un paquet de Mentos…\nSon esprit ne s’arrête jamais (genre, vraiment jamais.\nUn mot spécial de Julien…",
    description: "Il pilote JH et apporte sa vision stratégique sur les dossiers. Julien vit d’insights et de Coca-Cola, et son esprit ne s’arrête jamais (genre, vraiment jamais)."
  },
  {
    slug: "nico",
    name: "Nico",
    type: "video",
    voeux: "voeux_nico",
    attitude: "attitude_nico",
    icon: '/icones/nico.png',
    title: "Nico, c'est le<br/>Directeur de Création Associé",
    // description: "Artiste, manager du studio, musicien… Nico a plus d’une corde à sa guitare (et c’est quand même plus pratique).\nQuand vous entendez un « passe-moi mes baguettes » 🥁 c’est qu’il est l’heure du brainstorming. Et soudain, comme par magie, l’idée se révèle.\nNico vous souhaite pour 2022…",
    description: "Artiste, manager du studio, musicien… Nico a plus d’une corde à sa guitare ! Quand vous entendez un « passe-moi mes baguettes » 🥁 c’est qu’il est l’heure du brainstorming. Et comme par magie, l’idée se révèle..."
  },
  {
    slug: "morgane",
    type: "video",
    name: "Morgane",
    voeux: "voeux_morgane",
    attitude: "attitude_morgane",
    icon: '/icones/morgane.png',
    title: "Elle, c’est Morgane,<br/>la Dir-Cli de JH",
    // description: "Morgane orchestre avec une habileté sans pareille le dispatch des clients et des missions du pôle projet.\nElle vient en renfort des directrices projets sur les sujets stratégie et branding.\nSon énergie est communicative, surtout quand elle s’ambiance sur des sons des années 80.\nMorgane a un petit mot pour vous…",
    description: "Morgane orchestre avec une habileté sans pareille le pôle projet, en renfort sur les sujets stratégie et branding. Son énergie est communicative, surtout sur les sons des années 80."
  },
  {
    slug: "annececile",
    type: "video",
    name: "Anne-Cécile",
    voeux: "voeux_annececile",
    attitude: "attitude_annececile",
    icon: '/icones/annececile.png',
    title: "This is Anne-Cécile,<br/>Directrice Projets",
    // description: "Avec elle, ça file droit. Anne-Cé aime les challenges, et elle gère au quotidien des projets internationaux d’envergure.\nC’est une vraie main de fer dans un gant de velours (qui fait des gateaux en forme de coeur à ses collègues).\nIn 2022, she wishes you...",
    description: "Avec elle, ça file droit. Anne-Cé aime les challenges, et elle gère au quotidien des projets internationaux d’envergure. C’est une vraie main de fer dans un gant de velours (qui fait des gateaux en forme de coeurs)."
  },
  {
    slug: "jessica",
    type: "video",
    name: "Jessica",
    voeux: "voeux_jessica",
    attitude: "attitude_jessica",
    icon: '/icones/jessica.png',
    title: "Et voilà Jessica,<br/>Directrice Projets",
    // description: "Avec un oeil esthétique que Cristina Córdula lui envie, rien n’échappe à Jess.\nElle pilote des campagnes institutionnelles et de grandes marques avec une expérience certaine.\nMais Jess est aussi connue pour revisiter librement certaines expressions françaises.\nPour 2022, Jessica vous souhaite…",
    description: "Avec un oeil esthétique que Cristina Córdula lui envie, Jess pilote des campagnes institutionnelles et de grandes marques ! Mais elle est aussi connue pour revisiter librement certaines expressions françaises."
  },
  {
    slug: "severine",
    type: "video",
    name: "Séverine",
    voeux: "voeux_severine",
    attitude: "attitude_severine",
    icon: '/icones/severine.png',
    title: "Séverine, Directrice Projets<br/>et bien plus encore…",
    // description: " Si vous cherchez à mettre un peu de soleil dans votre journée, passez-lui un coup de fil, efficacité garantie.\nCe n'est pas pour rien que le titre de Chief Happiness Officer lui colle à la peau, et qu'elle endosse en plus le rôle de C.S.E au sein de l'agence.\n3 mots pour 2022, Séverine ?",
    description: "Si vous cherchez à mettre un peu de soleil dans votre journée, passez-lui un coup de fil, efficacité garantie. Ce n'est pas pour rien que les titres de Chief Happiness Officer et de C.S.E. lui collent à la peau."
  },
  {
    slug: "ines",
    type: "video",
    name: "Inès",
    voeux: "voeux_ines",
    attitude: "attitude_ines",
    icon: '/icones/ines.png',
    title: "Voici Inès,<br/>Social Media Manager",
    // description: "Queen des trends TikTok, elle pilote les différents sujets Social Media pour nos clients. Des stratégies d'influence à la gestion de campagnes Social selling, Inès conçoit aussi de chouettes lignes édito taillées au cordeau, tout comme ses cupcakes qui font l'unanimité !\nC’est une pilote hors-pair des plannings de publications et des reportings de campagnes.\nSon petit message pour vous ?",
    description: "Queen des trends TikTok, Inès est notre pilote hors-pair des différents sujets Social Media pour nos clients. Elle conçoit aussi des lignes édito taillées au cordeau, tout comme ses cupcakes qui font l'unanimité !"
  },
  {
    slug: "estelle",
    type: "video",
    name: "Estelle",
    voeux: "voeux_estelle",
    attitude: "attitude_estelle",
    icon: '/icones/estelle.png',
    title: "Estelle est Responsable<br/>des Stratégies Digitales",
    // description: "Estelle conçoit et pilote les campagnes digitales des clients et monte avec les Directrices Projets les recommandations stratégiques adéquates.\nElle a deux passions dans sa vie : le beurre de cacahuète et Edouard Baer. Si vous croisez par moment des constructions sémantiques capilotractées, ne cherchez pas plus loin. C'est qu'une crise de Baerite aïgue est en cours.\nEstelle vous souhaite pour 2022…",
    description: "Estelle conçoit et pilote les stratégies digitales de nos clients. Elle a deux passions : le beurre de cacahuète et Edouard Baer. Vous croiserez sûrement avec elle des constructions sémantiques capilotractées..."
  },
  {
    slug: "david",
    type: "video",
    name: "David",
    voeux: "voeux_david",
    attitude: "attitude_david",
    icon: '/icones/david.png',
    title: "David, c'est notre<br/>Responsable Projets Web",
    // description: "Dernier arrivé dans la team, il s'est tellement vite acclimaté à notre humour douteux que certains pensent que David est là depuis le début. Il est un concurrent sérieux au rire de Roméo, ce qu'on ne pensait pas concevable.\nAvec sa grande expérience, et son petit côté geek refoulé, David sait révéler et mettre en lumière le potentiel de chaque site web ou application.\nDavid a un petit conseil pour aborder 2022 sereinement…",
    description: "Dernier arrivé dans la team, il s'est vite acclimaté à notre humour douteux. Avec son expérience et son petit côté geek refoulé, David sait révéler le potentiel de chaque site web ou application."
  },
  {
    slug: "camille",
    type: "video",
    name: "Camille",
    voeux: "voeux_camille",
    attitude: "attitude_camille",
    icon: '/icones/camille.png',
    title: "C’est Camille,<br/>Chargée de Production",
    // description: "Camille voudrait que les journées fassent + de 24 heures.\nOn lui a dit que c’était pas possible, mais pour Camille rien n’est impossible, alors on attend de voir.\nShooting, prod, décli… C’est une femme-orchestre comme on en croise rarement dans une vie.\nElle a pris 5 minutes pour vous dire…",
    description: "Camille voudrait que les journées fassent + de 24 heures. On lui a dit que c’était pas possible, mais avec elle rien n’est impossible. Shooting, prod, décli… Une femme-orchestre comme on en croise rarement dans une vie."
  },
  {
    slug: "lila",
    type: "video",
    name: "Lila",
    voeux: "voeux_lila",
    attitude: "attitude_lila",
    icon: '/icones/lila.png',
    title: "Lila est<br/>Assistante Chargée de Projets",
    // description: "Discrète et efficace, elle est devenue une précieuse alliée pour les Directrices Projets.\nMais attention, Lila n’est pas du genre à rester sagement dans son périmètre.\nElle vous étonnera par sa polyvalence et ses nombreux talents !\nUn petit mot de Lila ?",
    description: "Discrète et efficace, elle est devenue une précieuse alliée pour les Directrices Projets. Mais attention, Lila vous étonnera par sa polyvalence et ses nombreux talents !"
  },
  {
    slug: "romeo",
    type: "video",
    name: "Roméo",
    voeux: "voeux_romeo",
    attitude: "attitude_romeo",
    icon: '/icones/romeo.png',
    title: "Voilà Roméo,<br/>le super Graphiste Maquettiste",
    // description: "Surnommé \"la machine\", il travaille tellement vite que même Illustrator n’arrive pas à le suivre. Il est multi-casquettes, mais la casquette qu’il préfère c’est celle de Dragon Ball Z.\nQuand vous entendez le rire de Roméo, vous savez que vous passerez une bonne journée.\nPour cette nouvelle année, Roméo vous souhaite…",
    description: 'Surnommé "la machine", il travaille tellement vite que même Illustrator n’arrive pas à le suivre. Roméo est multi-casquettes ! Mais la casquette qu’il préfère c’est celle de Dragon Ball Z.'
  },
  {
    slug: "simon",
    type: "video",
    name: "Simon",
    voeux: "voeux_simon",
    attitude: "attitude_simon",
    icon: '/icones/simon.png',
    title: "Et lui, c'est Simon,<br/>notre Graphiste Chromiste",
    // description: "Plus connu sous le pseudo PhotoShopMan, il est killer d'imperfections et activement engagé dans la L.R.D (Lutte pour le Respect du Détail).\nLes visuels de Simon sont si réalistes qu'il peut faire croire qu'un ciel est peuplé de poissons ou de méduses…\nEt comme tout bon prestidigitateur (à répéter 10x très vite avec une demie-mandarine dans la bouche) qui se respecte, Simon a toujours + d'un tour dans son sac…\nVous en voulez un petit échantillon ?",
    description: "C'est notre PhotoShopMan, killer d'imperfections par excellence. Les visuels de Simon sont si réalistes qu'il peut faire croire qu'un ciel est peuplé de méduses..."
  },
  {
    slug: "jordan",
    type: "video",
    name: "Jordan",
    voeux: "voeux_jordan",
    attitude: "attitude_jordan",
    icon: '/icones/jordan.png',
    title: "Lui, c’est Jordan,<br/>le Directeur Artistique de l’agence",
    // description: "Jojo pour les intimes, il est du genre à vous mettre des claques (visuelles, hein). Passionné et esthète extrémiste du détail, il nourrit de défis son esprit ultra créatif. Surconsommateur du site awwwards.com, il écume les internets pour flairer les prochaines tendances.\nExpérience, digital, et immersif sont ses keywords préférés.\nVoici son petit mot doux pour vous…",
    description: "Jojo pour les intimes, il est du genre à vous mettre des claques (visuelles, hein). Passionné et esthète extrémiste du détail, il imagine des expériences toujours plus immersives."
  },
  {
    slug: "dorian",
    type: "video",
    name: "Dorian",
    voeux: "voeux_dorian",
    attitude: "attitude_dorian",
    icon: '/icones/dorian.png',
    title: "Dorian est<br/>graphiste junior",
    // description: "Avec son œil déjà bien affuté pour un jeune padawan, il sait retranscrire efficacement une idée en création graphique.\nCalme et de sang froid, il créé l'équilibre avec les piles électriques qui l'entourent au studio.\nDorian n'est pas du genre à parler pour rien : il place toujours la bonne vanne au bon moment.\nD'ailleurs, pour 2022, il vous souhaite…",
    description: "Avec son œil déjà bien affuté pour un jeune padawan, calme et de sang froid, il créé l'équilibre avec les piles électriques qui l'entourent au studio. Et surtout, place toujours la bonne vanne au bon moment."
  },
];

export default memojis;