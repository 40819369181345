import React, { useEffect, useState } from "react"
import gsap from "gsap"
import { Transition } from "react-transition-group"
import * as animations from "../animations"
import Modal from "./modal"
import MemojiIcon from "./memoji-icon"
import useStore from "../store"

import * as styles from "../scss/team.module.scss"

const Team = () => {

  const [icons, setIcons] = useState([]);

  const [viewed, teamVisible, setTeamVisible, selectMemoji, randomizedMemojis, videoCache] = useStore(state => [state.viewed, state.teamVisible, state.setTeamVisible, state.selectMemoji, state.randomizedMemojis, state.videoCache])

  useEffect(() => {
    setIcons(() => {
      const colors = ['#00ADBA', '#F18700', '#18AE89', '#E4C900', '#F086A7', '#EC625D'];

      return randomizedMemojis.map((m, i) => {
        const icon = videoCache[`image_${m.slug}`];
        return <MemojiIcon onClick={() => { selectMemoji(m); setTeamVisible(false) }} big key={m.slug} icon={icon} color={gsap.utils.wrapYoyo(colors, i)} slug={m.slug} name={m.name} isChecked={viewed.includes(m.slug)} opacity />
      })
    })
  }, [viewed, videoCache]);

  return (
    <Transition
      appear
      in={teamVisible}
      timeout={300}
      mountOnEnter
      unmountOnExit
      onEnter={animations.slideIn}
      onExit={animations.slideOut}
    >
      {() => (
        <Modal onClose={() => setTeamVisible(false)} title="L'équipe" bg={teamVisible}>
          <div className={styles.team}>
            <div className={styles.wrapper}>
              {icons}
            </div>
          </div>
        </Modal>
      )}
    </Transition>
  );
};

export default Team;