import React, { useLayoutEffect, useRef } from 'react'
import { Ring, Jh, Stars } from "../components/svgs"
import gsap from "gsap"
import useStore from "../store"
import * as animations from "../animations"
import { isMobile } from 'react-device-detect'

import * as styles from '../scss/roulette.module.scss'

const Roulette = () => {

  const jhRef = useRef()
  const ringRef = useRef()
  const titleRef = useRef()
  const starsRef = useRef()

  const [setCurrentStep] = useStore(state => [state.setCurrentStep]) 

  useLayoutEffect(() => {
    const tl = gsap.timeline()
    tl.to(jhRef.current, { autoAlpha: 1, duration: 1.8, delay: 0.6 })
    tl.to(jhRef.current, { y: `-=${isMobile ? 115 : 205}`, duration: 0.5 }, 1.4)
    tl.to(ringRef.current, { autoAlpha: 1 },  1.4)
    tl.from(ringRef.current, { y: `-=${isMobile ? 20 : 50}` }, 1.5)
    tl.to(starsRef.current, { autoAlpha: 1, y: '-=90' },  1.55)
    tl.from(titleRef.current, { y: `+=${isMobile ? 40 : 80}` }, 1.5)
    tl.add(animations.lettersIn(titleRef.current), 1.8)
    tl.add(() => setCurrentStep(2), '+=1.2')
    return () => {
      tl.kill()
    }
  }, [])

  return (
    <div className={styles.intro}>
      <Ring ref={ringRef} className={styles.ring} />
      <Stars ref={starsRef} className={styles.stars}/>
      <div ref={titleRef} className={styles.title}>Memojis' Roulette</div>
      <Jh ref={jhRef} className={styles.jh} />
    </div>
  )
}

export default Roulette