import React, { useState, useLayoutEffect, useEffect, useRef } from "react"
import { Transition, TransitionGroup } from "react-transition-group"
import * as animations from "../animations"
import { Dash, Email, Play, Replay, Shuffle, Stop, Team } from "./svgs"
import Button from "./button"
import useStore from "../store"
import * as styles from "../scss/game-interface.module.scss"
import gsap from "gsap";
import ReactGA from 'react-ga4';

const GameInterface = () => {

    const [currentMemoji, viewed, setTeamVisible, setMessageVisible, randomizedMemojis, selectMemoji, play, setPlay, setRecipients, cookieConsent] = useStore(state => [state.currentMemoji, state.viewed, state.setTeamVisible, state.setMessageVisible, state.randomizedMemojis, state.selectMemoji, state.play, state.setPlay, state.setRecipients, state.cookieConsent]);

    const [memoji, setMemoji] = useState({ slug: 'demo', title: '', description: '', name: '' })

    const ref = useRef();
    const titleRef = useRef();
    const descRef = useRef();

    const shuffle = () => {
        const diff = randomizedMemojis.filter(m => !viewed.includes(m.slug) && m !== currentMemoji.slug)
        selectMemoji(gsap.utils.shuffle(diff)[0]);
    }

    const sendGA = () => {
        if (cookieConsent === true) {
            ReactGA.event({ category: 'level_up', action: 'view_wish', label: memoji.name });
        }
    }

    const anim = (node) => {
        const tl = gsap.timeline()
        tl.to(node, { autoAlpha: 1 })
        tl.add(animations.lettersIn(titleRef.current, 0.02), '<')
        tl.from(descRef.current, { opacity: 0, y: 50 }, "-=0.6")
    }

    const playVideo = () => {
        const av = document.querySelector('#carousel .swiper-slide-active .attitudevideo');
        const wv = document.querySelector('#carousel .swiper-slide-active .wishvideo');
        if (wv.paused) {
            setPlay(true)
            wv.currentTime = 0;
            gsap.to(av, { autoAlpha: 0, onComplete: () => { av.currentTime = 0 } });
            gsap.to(wv, { autoAlpha: 1 });
            wv.play().catch(err => console.log(err))
        } else {
            setPlay(false)
            wv.pause()
            gsap.to(av, { autoAlpha: 1 });
            gsap.to(wv, { autoAlpha: 0 });
        }
    }

    useEffect(() => {
        if (currentMemoji) {
            setMemoji(currentMemoji)
            gsap.to(ref.current, { autoAlpha: 1, duration: 0.3 })
            gsap.to('.game-interface-buttons', { opacity: 1, duration: 0.3, delay: 1.4})
        }
    }, [currentMemoji])

    return (
        <>
            <div className={`game-interface ${styles.gameInterface}`} ref={ref}>
                <div className={styles.contentContainer}>
                    <TransitionGroup component={null}>
                        <Transition key={memoji.slug} timeout={300} onEnter={anim}>
                            <div className={`content ${styles.content}`}>
                                <div ref={titleRef} className={styles.title} dangerouslySetInnerHTML={{ __html: memoji.title }}></div>
                                <div ref={descRef}>
                                    <Dash className={styles.dash} />
                                    <div className={styles.description}>{memoji.description}</div>
                                </div>
                            </div>
                        </Transition>
                    </TransitionGroup>
                </div>
            </div>
            <div className={`game-interface-buttons ${styles.buttons}`}>
                {/* <Button round shadow fillOnOver={play || (!play && !viewed.includes(memoji.slug))} onClick={() => { sendGA(); setPlay(play ? null : memoji.slug) }}> */}
                <Button round shadow fillOnOver={play || (!play && !viewed.includes(memoji.slug))} onClick={() => { sendGA(); playVideo() }}>
                    {play && <Stop />}
                    {!play && !viewed.includes(memoji.slug) && <Play />}
                    {!play && viewed.includes(memoji.slug) && <Replay />}
                </Button>
                <Button round shadow fillOnOver onClick={() => { setRecipients(null); setRecipients(memoji.slug); setMessageVisible(true) }}><Email /></Button>
                <Button round shadow onClick={() => setTeamVisible(true)}><Team /></Button>
                <Button round shadow onClick={shuffle}><Shuffle /></Button>
            </div>
        </>
    )
}

export default GameInterface