import React, {forwardRef} from "react"
import * as styles from "../scss/modal.module.scss"
import { Close, Dash } from "./svgs"

const Modal = forwardRef(({ children, className, floating, onClose, title, dash = true, bg = false }, ref) => {
  return (
    <>
    <div ref={ref} className={`${styles.modal} ${floating ? styles.floating : null} ${className}`}>
      {dash && <Dash className={styles.dash} />}
      {!!title && <div className={styles.title}>{title}</div>}
      {!!onClose && <div className={styles.close} onClick={onClose}><Close /></div>}
      {children}
    </div>
    {bg && <div className={styles.bg} onClick={() => !!onClose ? onClose() : null}></div>}
    </>
  )
});

export default Modal