import React, { useEffect, useState } from "react"
import { Transition } from "react-transition-group"
import { Facebook, Twitter, Instagram, LinkedIn } from '../components/svgs'
import Button from "./button"
import * as animations from "../animations"
import Modal from "./modal"
import useStore from "../store"

import * as styles from "../scss/social-mobile.module.scss"


const SocialMobile = () => {

  const [mobileSocialVisible, setMobileSocialVisible] = useStore(state => [state.mobileSocialVisible, state.setMobileSocialVisible])

  return (
    <Transition
      appear
      in={mobileSocialVisible}
      timeout={300}
      mountOnEnter
      unmountOnExit
      onEnter={animations.slideIn}
      onExit={animations.slideOut}
    >
      {() => (
        <Modal floating onClose={() => setMobileSocialVisible(false)} title="Nous suivre" bg={mobileSocialVisible}>
          <div className={styles.wrapper}>
            <div className={styles.buttons}>
              <Button round transparent onClick={() => window.open("https://www.facebook.com/agence.justhappiness/")} ><Facebook /></Button>
              <Button round transparent onClick={() => window.open("https://twitter.com/JustHappinessJH")}><Twitter /></Button>
              <Button round transparent onClick={() => window.open("https://www.instagram.com/we_are_justhappiness/")}><Instagram /></Button>
              <Button round transparent onClick={() => window.open("https://www.linkedin.com/company/just-happiness/")}><LinkedIn /></Button>
            </div>
          </div>
        </Modal>
      )}
    </Transition>
  );
};

export default SocialMobile;