import React, { useEffect, useRef, useState } from "react"
import Bowser from "bowser"
import preload from "../lib/preload-it/main"
import * as styles from "../scss/loader.module.scss"
import memojis from "../memojis"
import useStore from "../store"

const Loader = ({ onComplete }) => {

  const ref = useRef()
  const [setVideoCache, cookieChoice, loadedAttitudeVideos] = useStore(state => [state.setVideoCache, state.cookieChoice, state.loadedAttitudeVideos])
  const [className, setClassName] = useState(styles.hidden)
  const assetsLoaded = useRef(0)
  const videosLoaded = useRef(0)
  const isDone = useRef(false)

  const updateText = () => {
    if (!ref.current) return;
    let fetched = assetsLoaded.current + videosLoaded.current;
    let f = Math.round(fetched * 100 / 30)
    if (f > 100) {
      f = 100;
    }
    ref.current.innerText = f;
  }

  useEffect(() => {
    if (!cookieChoice) {
      return;
    }
    // const browser = Bowser.getParser(window.navigator.userAgent);
    const bp = Bowser.parse(window.navigator.userAgent);
    const isSafari = (bp.browser.name === 'Safari' || (bp.os.name === 'iOS'))
    const extension = isSafari ? 'mp4' : 'webm'
    const images = memojis.map(m => `${m.icon}`)
    const files = memojis.map(m => `/memojis/${m.attitude}.${extension}`)
    const tbf = [...images, ...files];
    // const tbf = images;
    const p = preload()
    // p.onprogress = event => {
    //   ref.current.innerText = event.progress;
    // }
    p.onfetched = () => {
      assetsLoaded.current += 1;
      updateText();
    }
    p.oncomplete = (f) => {
      // console.log("ICONS LOADED");
      setVideoCache(f)
    }
    p.fetch(tbf)
  }, [cookieChoice])

  useEffect(() => {
    if (!cookieChoice || !loadedAttitudeVideos) {
      return;
    }
    videosLoaded.current += 1;
    updateText();
  }, [loadedAttitudeVideos, cookieChoice])

  useEffect(() => {
    // if (assetsLoaded.current == 15 && videosLoaded.current == 15 && isDone.current === false) {
      // console.log(assetsLoaded.current)
    if (assetsLoaded.current >= 30 && isDone.current === false) {
      // console.log("CONTENT LOADED !", assetsLoaded.current, videosLoaded.current, isDone.current);
      isDone.current = true;
      onComplete();
    }
  }, [assetsLoaded.current, videosLoaded.current])

  useEffect(() => {
    setClassName(cookieChoice ? styles.visible : styles.hidden)
  }, [cookieChoice])

  return (
    <div ref={ref} className={`${styles.loader} ${className}`}>0</div>
  )
}

export default Loader