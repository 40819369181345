import React, {useLayoutEffect, useRef} from 'react'
import Cookies from "js-cookie";
import { Dash} from '../components/svgs'
import Button from '../components/button'
import gsap from "gsap"
import * as animations from "../animations"

import * as styles from '../scss/welcome.module.scss'

const Welcome = ({onClick}) => {

  const titleRef = useRef()
  const dashRef = useRef()
  const textWhiteRef = useRef()
  const textRef = useRef()
  const buttonRef = useRef()
  const buttonTextRef = useRef()

  useLayoutEffect(() => {
    const tl = gsap.timeline()
    tl.set(titleRef.current, {yPercent: 100})
    tl.add(animations.lettersIn(titleRef.current), 0.6)
    tl.to(titleRef.current, { yPercent: 0})
    tl.to(dashRef.current, { autoAlpha: 1}, '<')
    tl.from(dashRef.current, { y: '+=20', duration: 0.7}, '<')
    tl.from(textWhiteRef.current, { y: '+=20', duration: 0.6}, '<')
    tl.to(textWhiteRef.current, { autoAlpha: 1}, '<')
    tl.from(textRef.current, { y: '+=20', duration: 0.8}, '<')
    tl.to(textRef.current, { autoAlpha: 1}, '<')
    tl.from(buttonRef.current, { y: '+=20', duration: 1}, '<')
    tl.to(buttonRef.current, { autoAlpha: 1}, '<')
    tl.to(buttonTextRef.current, { opacity: 0, repeat: -1, yoyo: 1, duration: 1.4, ease: 'power2.inOut'})
    return () => {
      tl.kill()
    }
  }, [])

  return (
    <div className={styles.welcome}>
      <div ref={titleRef} className={styles.title}>Bienvenue<br/> en l'an 2022 !</div>
      <Dash ref={dashRef} className={styles.dash}/>
      <div ref={textWhiteRef} className={styles.textWhite}>
        La Team JH a plein de choses à vous souhaiter pour cette nouvelle année !
      </div>
      <div ref={textRef} className={styles.text}>
        Visionnez les voeux d’au moins 3 membres pour pouvoir accéder à un bonus surprise…
      </div>
      <Button ref={buttonRef} className={styles.button} onClick={onClick}><span ref={buttonTextRef}>{Cookies.get('viewed') ? 'Reprendre' : 'Démarrer'}</span></Button>
    </div>
  )
}

export default Welcome 