import React, { useEffect, useState } from "react"
import useStore from "../store"

import * as styles from "../scss/too-small.module.scss"

const TooSmall = () => {

  const messageVisible = useStore(state => state.messageVisible)
  const [content, setContent] = useState(null)

  useEffect(() => {
    if (messageVisible) {
      setContent(null)
    } else {
      setContent(<div className={`too-small ${styles.container}`}>
        <div className={styles.text}>Pour profiter pleinement de nos voeux cette année, nous vous recommandons de visiter ce site sur un écran plus grand ou avec votre ordinateur.<br /><br />Merci, et bonne année 2022 !</div>
      </div>)
    }
  }, [messageVisible])

  return content
};

export default TooSmall;