import React, { useLayoutEffect, useEffect, useRef, useState } from "react"
import { Transition } from "react-transition-group"
import { Star } from "./svgs"
import useStore from "../store"
import gsap from "gsap"

import * as animations from "../animations"
import * as styles from "../scss/overlay.module.scss"

const Overlay = () => {

  const [stars, overlayVisible, setOverlayVisible] = useStore(state => [state.stars, state.overlayVisible, state.setOverlayVisible])
  const [content, setContent] = useState(['', ''])

  const star1Ref = useRef()
  const star2Ref = useRef()
  const star3Ref = useRef()

  useEffect(() => {
    if (overlayVisible === true) {
      setTimeout(() => {
        setOverlayVisible(false);
      }, 2500)
    }
  }, [overlayVisible, setOverlayVisible])

  useLayoutEffect(() => {
    if (!overlayVisible || stars > 3) {
      return;
    }
    switch (stars) {
      case 1:
        setContent(['Génial !', 'Plus que 2 voeux à voir'])
        gsap.from(star1Ref.current, { transform: 'scale(0.3)', delay: 0.20, ease: "back.inOut(2.7)", duration: 0.4 })
        break;
      case 2:
        setContent(['Super !', 'Plus qu\'un voeu à visionner'])
        gsap.from(star2Ref.current, { transform: 'scale(0.3)', delay: 0.20, ease: "back.inOut(2.7)", duration: 0.4 })
        break;
      default:
        setContent(['Oh yeah !', ''])
        gsap.from(star3Ref.current, { transform: 'scale(0.3)', delay: 0.20, ease: "back.inOut(2.7)", duration: 0.4 })
    }
  }, [stars, overlayVisible])

  return (
    <Transition in={overlayVisible} timeout={300} appear mountOnEnter unmountOnExit onEnter={animations.opacityIn} onExit={animations.opacityOut}>
      <div className={styles.overlay} onClick={() => setOverlayVisible(false)}>
        <div className={styles.title}>{content[0]}</div>
        <div className={styles.stars}>
          <Star ref={star1Ref} className={stars >= 1 ? styles.star : styles.starDisabled} />
          <Star ref={star2Ref} className={stars >= 2 ? styles.star : styles.starDisabled} />
          <Star ref={star3Ref} className={stars === 3 ? styles.star : styles.starDisabled} />
        </div>
        {content[1]}
      </div>
    </Transition>
  )
}

export default Overlay;