import gsap from 'gsap'
import SplitText from 'gsap/SplitText';

export const slideIn = (node) => { gsap.fromTo(node, { y: 100, duration: 0.3 }, { y: 0 }); gsap.to(node, { autoAlpha: 1, duration: 0.3 }) }

export const slideInFloating = (node) => { gsap.fromTo(node, { y: 100, duration: 0.3 }, { y: 'auto', yPercent: -50 }); gsap.to(node, { autoAlpha: 1, duration: 0.3 }) }

export const slideOut = (node) => gsap.to(node, { autoAlpha: 0, y: 100, duration: 0.3 })

export const slideDown = (node) => gsap.fromTo(node, { autoAlpha: 1, yPercent: -100 }, { autoAlpha: 1, yPercent: 0 })

export const slideUp = (node) => gsap.fromTo(node, { autoAlpha: 0, yPercent: 100 }, { autoAlpha: 1, yPercent: 0 })

export const opacityIn = (node) => gsap.fromTo(node, { autoAlpha: 0, pointerEvents: 'none', duration: 0.3 }, { autoAlpha: 1, pointerEvents: 'all', duration: 0.3 })

export const opacityOut = (node) => gsap.to(node, { autoAlpha: 0, pointerEvents: 'none', duration: 0.3 })

export const blurIn = (node) => gsap.fromTo(node, { autoAlpha: 0, filter: 'blur(2px)', duration: 0.3 }, { autoAlpha: 1, filter: 'blur(0)', duration: 0.3 })

export const blurOut = (node) => gsap.to(node, { autoAlpha: 0, filter: 'blur(2px)' })

export const lettersIn = (node, stagger = 0.05) => {
  const tl = new gsap.timeline()
  tl.from(node, { opacity: 0, duration: 0.1 })
  const lines = new SplitText(node).lines
  node.innerHTML = ''
  lines.forEach((line, i) => {
    node.appendChild(line)
    const letters = new SplitText(line).chars
    line.style.display = 'block';
    line.style.clipPath = 'inset(0px 0px 0px 0px)'
    tl.from(letters, {
      duration: 1,
      yPercent: 100,
      rotation: 10,
      stagger,
      ease: "power3.inOut"
    }, (i > 0 ? "-=1": ""))
  })
  return tl;
}