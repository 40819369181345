import React from 'react'
import { Jh, Star } from "./svgs"
import * as styles from '../scss/header.module.scss'
import useStore from "../store.js"
import BravoPopin from "../components/bravo-popin"
import { isMobile } from 'react-device-detect'

const Header = () => {

  const { viewed, stars } = useStore();
  const prefixedStars = (viewed.length) < 10 ? '0' + viewed.length : viewed.length;
  const starElts = [];
  for (let i = 0; i <= 2; i++) {
    const cn = (i < stars) ? styles.starActive : styles.star;
    starElts.push(<Star key={i} className={cn} />)
  }
  return (
    <div className={styles.header}>
      <div className={styles.left}>
        Score
        <div className={styles.scorePoints}>
          {prefixedStars}<span className={styles.scoreSep}>/</span>15
        </div>
      </div>
      <div className={styles.center}>
        <a href="https://justhappiness.fr/?utm_source=voeux2022" target="_blank"><Jh className={styles.jh} /></a>
      </div>
      <div className={styles.right}>
        {(!isMobile || (isMobile && viewed.length < 3)) && (
          <div>
            Bonus
            <div className={styles.bonusStars}>
              {starElts}
            </div>
          </div>
        )}
        {isMobile && <BravoPopin />}
      </div>
    </div >
  )
}

export default Header