import create from "zustand"
import memojis from "./memojis"
import ReactGA from 'react-ga4';
import { setCookie, getCookie } from "./components/cookie-consent"
import gsap from "gsap";

// Hydratation du store à partir des cookies
const cookiesViewed = getCookie('viewed') ? getCookie('viewed').split(',') : [];
// Suppression des memojis déjà vus 
const diff = memojis.filter(m => !cookiesViewed.includes(m.slug))
// Randomization
const shuffle = gsap.utils.shuffle(diff);
// Extraction de ceux déjà vus
const alreadyViewed = memojis.filter(m => cookiesViewed.includes(m.slug))
// Configuration
const cookiesConfiguration = getCookie('configuration') ? JSON.parse(getCookie('configuration')) : { stars: 40, noise: true, bokeh: true };

const useStore = create(set => {
  return ({
    viewed: cookiesViewed,
    stars: cookiesViewed.length,
    randomizedMemojis: [...shuffle, ...alreadyViewed], // Ajout de ceux déjà vus à la fin
    recipients: [],
    messageVisible: false,
    teamVisible: false,
    bravoVisible: false,
    bravoPopinVisible: cookiesViewed.length >= 3,
    legalVisible: false,
    overlayVisible: false,
    mobileSocialVisible: false,
    achievementUnlocked: false,
    cookieConsentVisible: false,
    loadedAttitudeVideos: [],
    cookieConsent: JSON.parse(getCookie('cookie_consent')),
    configuration: cookiesConfiguration,
    cookieChoice: ['true', 'false'].includes(getCookie('cookie_consent')),
    currentStep: 0,
    videoCache: {},
    play: null,
    currentMemoji: null,
    selectedMemoji: null,
    setViewed: (slug) => set(state => {
      const viewed = [...new Set([...state.viewed, slug])]
      setCookie('viewed', viewed.toString())
      const ret = { stars: (viewed.length < 3) ? viewed.length : 3, viewed };
      if (!state.viewed.includes(slug) && viewed.length <= 3) {
        ret.overlayVisible = true;
      }
      if (viewed.length === 3) {
        ReactGA.event({ category: 'level_up', action: 'unlock_achievement', achievement_id: 'VIDEO_BONUS' });
      }
      return ret;
    }),
    setRecipients: (slug) => set(state => {
      if (slug === null) {
        return { recipients: [] }
      }
      if (state.recipients.includes(slug)) {
        return { recipients: state.recipients.filter(r => r !== slug) }
      } else {
        return { recipients: [...state.recipients, slug] }
      }
    }),
    setMessageVisible: (visibility) => set({ messageVisible: visibility }),
    setTeamVisible: (visibility) => set({ teamVisible: visibility }),
    setBravoVisible: (visibility) => set({ bravoVisible: visibility }),
    setBravoPopinVisible: (visibility) => set({ bravoPopinVisible: visibility }),
    setLegalVisible: (visibility) => set({ legalVisible: visibility }),
    setCookieConsent: (choice) => set({ cookieConsent: choice }),
    setCookieConsentVisible: (visibility) => set({ cookieConsentVisible: visibility }),
    setAchievementUnlocked: (visibility) => set({ achievementUnlocked: visibility }),
    setOverlayVisible: (visibility) => set((state) => {
      const ret = { overlayVisible: visibility };
      if (visibility === false && state.viewed.length === 3 && state.achievementUnlocked === false) {
        ret.achievementUnlocked = true;
        ret.bravoVisible = true;
      }
      return ret
    }),
    setMobileSocialVisible: (visibility) => set({ mobileSocialVisible: visibility }),
    setCurrentMemoji: (currentMemoji) => set({ currentMemoji }),
    selectMemoji: (selectedMemoji) => set({ selectedMemoji }),
    setCurrentStep: (currentStep) => set({ currentStep }),
    setPlay: (memoji) => set({ play: memoji }),
    setCookieChoice: (choice) => set({ cookieChoice: choice }),
    setConfiguration: (configuration) => { setCookie('configuration', JSON.stringify(configuration)); set({ configuration }) },
    setLoadedAttitudeVideos: (slug) => set((state) => {
      // const items = { loadedAttitudeVideos: [...new Set([...state.loadedAttitudeVideos, slug])] }
      return { loadedAttitudeVideos: [...state.loadedAttitudeVideos, slug] }
    }),
    setVideoCache: (files) => set(() => {
      const outp = {}
      files.forEach((file) => {
        if (file.fileName.match(/\.png$/)) {
          outp['image_' + file.fileName.split('.')[0]] = file.blobUrl;
        } else {
          outp['video_' + file.fileName.split('.')[0].split('_')[1]] = file.blobUrl;
        }
      })
      return { videoCache: outp };
    })
  })
})

export default useStore