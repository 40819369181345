import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import useStore from "../store"
import Bowser from "bowser";
import * as styles from "../scss/memoji.module.scss"

const Memoji = ({ color, isVisible, isActive, voeux, slug, onMouseEnter: onMouseEnterCallback }) => {

  // const imageRef = useRef();
  const wishRef = useRef()
  const attitudeRef = useRef();
  const mainRef = useRef()
  // const isPlayingRef = useRef(false);
  // const isVideoLoadedRef = useRef(false);
  const [play, setPlay, setViewed, viewed, videoCache] = useStore(state => [state.play, state.setPlay, state.setViewed, state.viewed, state.videoCache])
  // const browser = Bowser.getParser(window.navigator.userAgent);
  // const isSafari = useRef(browser.satisfies({ safari: "> 1" }));
  const bp = Bowser.parse(window.navigator.userAgent);
  const isSafari = useRef(bp.browser.name === 'Safari' || bp.os.name === 'iOS');
  // const isInited = useRef(false);

  // useEffect(() => {
  //   if (!videoCache) return;
  //   const vid = mainRef.current.querySelector(`video[data-slug=${slug}]`)
  //   // vid.innerHTML = '';
  //   // console.log("SET VIDEO", videoCache[`video_${slug}`], isSafari.current, slug)
  //   // const source = document.createElement('source');
  //   // source.setAttribute('src', videoCache[`video_${slug}`])
  //   // source.setAttribute('type', isSafari.current ? 'video/mp4; codecs="hvc1"' : 'video/webm')
  //   vid.src = videoCache[`video_${slug}`];
  //   // vid.appendChild(source);
  //   vid.load();
  //   // vid.addEventListener('canplaythrough', () => {
  //   //   // imageRef.current.style.opacity = 0;
  //   //   // imageRef.current.style.visibility= 'hidden';
  //   //   console.log("CAN PLAY TROUGH")
  //   //   vid.play();
  //   //   // console.log("CPT");
  //   //   // if (videoRef.current) {
  //   //   //   videoRef.current.style.opacity = 0;
  //   //   //   videoRef.current.style.visibility = 'hidden';
  //   //   // }
  //   //   // attitudeRef.current.style.opacity = 1;
  //   //   // attitudeRef.current.style.visibility = 'visible';
  //   // })
  //   // mainRef.current.querySelector(`img`).src = videoCache[`image_${slug}`]
  // }, [])

  // const playVideo = () => {
  //   const av = document.querySelector('#carousel .swiper-slide-active .attitudevideo');
  //   const wv = document.querySelector('#carousel .swiper-slide-active .wishvideo');
  //   // Don't relaunch video if playing
  //   if (!wv.paused) {
  //     return;
  //   }
  //   gsap.to(av, { autoAlpha: 0 });
  //   gsap.to(wv, {
  //     autoAlpha: 1, onComplete: () => {
  //       av.currentTime = 0
  //       wv.currentTime = 0;
  //       wv.play().catch(err => console.log(err))
  //       setPlay(slug)
  //     }
  //   });
  // }

  const playVideo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const av = document.querySelector('#carousel .swiper-slide-active .attitudevideo');
    const wv = document.querySelector('#carousel .swiper-slide-active .wishvideo');
    if (wv.paused) {
      wv.currentTime = 0;
      gsap.to(av, { autoAlpha: 0, onComplete: () => { av.currentTime = 0 } });
      gsap.to(wv, { autoAlpha: 1 });
      wv.play().catch(err => console.log(err))
      setPlay(true)
    } else {
      return;
      // wv.pause()
      // gsap.to(av, { autoAlpha: 1 });
      // gsap.to(wv, { autoAlpha: 0 });
      // setPlay(false)
    }
  }

  // const playVideo = useCallback(() => {
  //   wishRef.current.currentTime = 0;
  //   wishRef.current.play().catch((error) => console.log(error))
  //   // isPlayingRef.current = true;
  //   gsap.to(attitudeRef.current, { autoAlpha: 0, duration: 0.3 })
  //   gsap.to(wishRef.current, { autoAlpha: 1, duration: 0.3 })
  // }, []);

  // useLayoutEffect(() => {
  //   // const video = mainRef.current.querySelector('.wishVideo')
  //   console.log("ICI", play, slug)
  //   if (play === slug && isVisible.current) { // only requested memoji
  //     // playVideo();
  //     wishRef.current.currentTime = 0;
  //     wishRef.current.src = isSafari.current ? `memojis/${voeux}.mp4` : `memojis/${voeux}.webm`
  //     wishRef.current.play().catch((error) => console.log(error))
  //     // isPlayingRef.current = true;
  //     gsap.to(attitudeRef.current, { autoAlpha: 0, duration: 0.3 })
  //     gsap.to(wishRef.current, { autoAlpha: 1, duration: 0.3 })
  //   }
  //   // if (isPlayingRef.current) {
  //   //   isPlayingRef.current = false;
  //   //   gsap.to(attitudeRef.current, { autoAlpha: 1, duration: 0.3 })
  //   //   gsap.to(widthRef.current, { autoAlpha: 0, duration: 0.3 })
  //   //   wishRef.current.pause();
  //   // }
  // }, [play, slug, isVisible.current])

  useEffect(() => {
    // Sync preloader when attitude video loaded
    // const syncPreloader = () => {
    //   setLoadedAttitudeVideos(slug)
    //   attitudeRef.current.removeEventListener('canplay', syncPreloader)
    // }
    // attitudeRef.current.addEventListener('canplay', syncPreloader)
    // Bind autoplay on wish video
    // isInited.current = true;
    // wishRef.current.addEventListener('canplaythrough', playVideo)
    // wishRef.current.addEventListener('play', () => {
    //   // wishRef.current.removeEventListener('canplaytrough', playVideo)
    // });
    wishRef.current.addEventListener('ended', () => {
      // isPlayingRef.current = false;
      setViewed(slug)
      setPlay(null)
    });
  }, [])

  // useEffect(() => {
  //   if (isVisible === false) {
  //     gsap.to(attitudeRef.current, { autoAlpha: 1, duration: 0.3 })
  //     gsap.to(videoRef.current, { autoAlpha: 0, duration: 0.3 })
  //   }
  // }, [isVisible])

  return (
    <div ref={mainRef} className={styles.memoji} onMouseEnter={onMouseEnterCallback} onClick={playVideo} style={{ opacity: !isActive && viewed.includes(slug) ? 0.6 : 1 }}>
      <div className={styles.halo} style={{ backgroundColor: color }}></div>
      {/* <video className={styles.attitude} src={videoCache[`video_${slug}`]} data-slug={slug} ref={attitudeRef} width="480" height="100%" muted playsInline preload="auto"></video> */}
      {/* <video className={`attitudevideo ${styles.attitude}`} src={`memojis/attitude_${slug}.${isSafari.current ? 'mp4' : 'webm'}`} data-slug={slug} ref={attitudeRef} width="480" height="100%" muted playsInline preload="auto"></video> */}
      <video className={`attitudevideo ${styles.attitude}`} src={videoCache[`video_${slug}`]} data-slug={slug} ref={attitudeRef} width="480" height="100%" muted playsInline preload="auto"></video>
      {/* <img className={styles.image} ref={imageRef} /> */}
      {/* {((isVisible && play === slug) || (isInited.current === true)) && ( */}
      <video ref={wishRef} src={isSafari.current ? `memojis/${voeux}.mp4` : `memojis/${voeux}.webm`} className={`wishvideo ${styles.video}`} data-slug={slug} width="480" height="100%" playsInline preload="none">
        {/* <video ref={wishRef} className={`wishvideo ${styles.video}`} width="480" height="100%" playsInline preload="none"> */}
        {/* <source
            src={isSafari ? `memojis/${voeux}.mp4` : `memojis/${voeux}.webm`}
            type={isSafari ? 'video/mp4; codecs="hvc1"' : 'video/webm'} >
          </source> */}
      </video>
      {/* )} */}
    </div >
  )
};

export default Memoji;